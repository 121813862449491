// standards for screen sizes (media queries)
@mixin mobile {
  @media (min-width: 320px) and (max-width: 576px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 577px) and (max-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin desktopXL {
  @media (min-width: 1801px) {
    @content;
  }
}

// flexbox mixin
@mixin flexbox($flex-direction: false,
  $justify-content: false,
  $align-items: false,
  $gap: false,
  $flex: false) {
  display: flex;

  @if $flex-direction !=false {
    flex-direction: $flex-direction;
  }

  @if $justify-content !=false {
    justify-content: $justify-content;
  }

  @if $align-items !=false {
    align-items: $align-items;
  }

  @if $gap !=false {
    gap: $gap;
  }
}

@function text3D($depth, $color) {
  $value: '0px 0px 0 #{$color}';

  @for $i from 1 through $depth {
    $value: '#{$value} , #{$i * 1}px #{$i * 1}px 0'+$color;
  }

  @return unquote($value);
}

//angled background mixin
@mixin angle($pseudo, $flip: false, $angle: 4deg) {

  // Possible values for $pseudo are: before, after, both
  @if $pseudo =='before' or $pseudo =='after' or $pseudo =='both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo =='both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 50%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
    }

    @if $pseudo =='before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo =='after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        }

        @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo =='both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        }

        @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}