.card_container {
  @extend %card;
  flex: 1 1 45%;
  min-width: 25%;
  height: 400px;
  background-color: #FFF;

  border: 5px solid $color-main;
  box-shadow: 6px 6px 0px 0 $color-main;

  @include tablet {
    flex: 1 1 30%;
    height: 360px;
    border: 4px solid $color-main;
    box-shadow: 5px 5px 0px 0 $color-main;
  }

  @include mobile {
    flex: 1 1 100%;
    height: 300px;
    border: 3px solid $color-main;
    box-shadow: 3px 3px 0px 0 $color-main;
  }

  @include desktopXL {
    flex: 1 1 30%;
  }
}

// position must be relative for link 'overlay' to show
.card_img {
  flex: 1 1 80%;
  position: relative;
  overflow: hidden;
  max-height: 300px;
}

.card_img img {
  width: 100%;
  object-fit: cover;
}

.card_link {
  height: 36px;
  padding: 0 20px 0 15px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  background: $color-main;
  position: absolute;
  right: 0;
  bottom: 15px;
  z-index: 10;
  transition: all 200ms;

  &:hover {
    background: $color-accent;
    border-radius: 18px;
    padding: 0 10px 0 15px;
    right: 10px;
    box-shadow: 2px 1px 4px 0 $color-main;
    transition: all 500ms;

    a {
      color: $color-main;
      font-weight: 600;
    }
  }
}

.card_link a {
  font: map-get($textstyle, code);
  font-size: 0.8rem;
  color: $color-light;
  text-decoration: none;
  line-height: 36px;

  @include mobile {
    font-size: 0.9rem;
  }
}

.card_info {
  flex: 1 1 20%;
  @include flexbox(column, space-between);
  padding: 15px $small;
  color: $color-main;
  border-top: 4px solid $color-main;

  @include tablet {
    min-height: 130px;
    padding: 10px 15px 15px;
    border-top: 3px solid $color-main;
  }

  @include mobile {
    min-height: min-content;
    box-shadow: 4px 4px 0px 0 $color-main;
  }
}

.card_title {
  @include flexbox(row, space-between, center);
}

.card_title p {
  line-height: 1.5rem;
  margin: 0;
  align-self: flex-end;
}

.card_description {
  font-weight: 500;
  line-height: 1.1rem;
  margin-top: 5px;
  margin-right: $half;

  @include mobile {
    margin-right: 0;
  }
}

.card_tags {
  list-style: none;
  @include flexbox(row, flex-start, center);
  flex-wrap: wrap;
  gap: 8px;
  padding-inline-start: 0;
  margin-right: $half;
}

.card_tags li {
  height: 20px;
  padding: 0 8px;
  border-radius: $small;
  background: $color-accent;

  font: map-get($textstyle, small);
  font-weight: 600;
  text-align: center;
  line-height: 20px;

  @include desktopXL {
    height: 30px;
    padding: 0px 12px;
    line-height: 30px;
  }
}