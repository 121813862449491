// SCSS FILES //
@import '../mixins.scss';
@import '../variables.scss';

header {
  @extend %centered;
  @include flexbox(column, center, stretch);
  padding: $small $half;
  background-color: $color-main-transparent;
  box-shadow: 0 0 2px 2px #00221c40;
  position: relative;
  top: 0;
  z-index: 100;
  cursor: pointer;

  @include mobile {
    padding: 16px $small;
  }

  @include desktopXL {
    padding: 30px $full;
  }
}

.header_container {
  @include flexbox(row, space-between, center);
}

.header_logo {
  height: 20px;
}

.header_nav {
  @include flexbox(row, space-between, center, 10px);

  @include desktopXL {
    gap: $half
  }
}

.header_nav a {
  width: 80px;
  text-align: center;
  font: 400 0.9rem $font-accent;
  letter-spacing: 0.1rem;
  color: $color-light;
  text-decoration: none;

  &:focus {
    pointer-events: none;
    text-decoration: underline;
    text-underline-offset: 7px;
    color: $color-alt;
  }

  &:hover {
    color: $color-accent;
    font-weight: 500;
    font-size: 1rem;
  }
}