// variables for colors, fonts, sizes
$font-main: 'Mulish', sans-serif;
$font-accent: 'Phudu', Tahoma, cursive;
$font-code: 'Inconsolata', monospace;

/** text-styles (weight, size, line-height) **/
$textstyle: (
  xlarge: 900 1.6rem $font-main,
  large: 700 1.2rem $font-main,
  regular: 300 1.0rem $font-main,
  bold: 700 1.0rem $font-main,
  small: 0.8rem $font-main,
  thin: 200 $font-main,

  tagline: 600 3rem $font-accent,
  code: 400 1rem $font-code,
);

$color-main: #004236;
$color-alt: #52A17E;
$color-accent: #b798ff;
$color-light: #BDE0C0;
$color-transparent: #bde0c090;
$color-main-transparent: #004236ee;

// margins and padding
$full: 80px;
$half: 40px;
$small: 20px;
$gap: 20px;
$radius-lg: 25px;
$radius-med: 15px;
$radius-sm: 5px;

%centered {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

%card {
  @include flexbox(column, flex-start);
  cursor: pointer;
  padding: 0px;
  border-radius: $radius-lg;
  overflow: hidden;

  @include mobile {
    border-radius: $radius-med;
  }
}