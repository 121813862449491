// import fonts used from google //
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Phudu:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;700&display=swap');

// SCSS FILES //
@import "./styles/mixins.scss";
@import "./styles/variables.scss";

// component styles //
@import "./styles/components/footer";
@import "./styles/components/header";
@import "./styles/components/hero";
@import "./styles/components/card";
@import "./styles/components/contact";


// BREAKPOINTS REFERENCE:
/* standards for screen sizes (media queries)
/* designed desktop-first
-desktopXL: min 1800px
-tablet: max 992px
-mobile: max 576px
*/

html {
  font-size: 16px;
  font: map-get($textstyle, regular);

  @include mobile {
    font-size: 14px;
  }

  @include desktopXL {
    font-size: 20px;
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  @include desktopXL {
    background: #FFF;
  }
}

.App_container {
  height: 100%;
  max-height: 100%;
  @extend %centered;
  width: 100%;
  max-width: 1800px;
  color: #FFF;
  background: $color-main;

  @include desktopXL {
    border: 5px solid $color-main;
  }
}

h1,
h2,
h3,
h4,
p,
a,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

h1 {
  font: map-get($textstyle, tagline);
  letter-spacing: 3px;
  color: #FFF;
  text-shadow: text3D(3, $color-accent);

  margin: 0 0 $small;

  @include mobile {
    font-size: 2.2rem;
    margin-bottom: 5px;
  }
}

h2 {
  font: map-get($textstyle, xlarge);
  letter-spacing: 1px;
  text-transform: uppercase;

  margin: 0 0 $half;

  @include tablet {
    margin: 0 0 10px;
  }

  @include mobile {
    margin: 0 0 25px;
  }
}

h3 {
  font: map-get($textstyle, large);
  color: $color-accent;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: text3D(1, #FFF);

  margin: $small 0;
}

h4 {
  font: map-get($textstyle, large);
  font-weight: 600;
  line-height: 1.5rem;

  margin: 0;
}

p {
  font: map-get($textstyle, regular);
  margin-bottom: 10px;
  ;
}

a.button {
  color: $color-main;
  font: map-get($textstyle, code);
  font-weight: 600;
  text-decoration: none;
  text-align: right;
  display: inline-block;
  padding: 6px 8px 4px;
  width: 80px;
  min-width: 60px;
  background-color: #FFF;
  border-radius: 4px;
  border: 2px solid $color-alt;
  box-shadow: 2px 2px 0 0 $color-alt;

  &:hover {
    color: $color-accent;
    border: 2px solid $color-accent;
    box-shadow: 2px 2px 0 0 $color-accent;
    transition: 0.2s ease all;
  }

  @include mobile {
    width: 30px;
    padding: 5px 6px 3px;
    font-size: 0.9rem;
  }
}

.projects_container {
  padding: $half $full;
  position: relative;
  background: $color-light;
  margin-bottom: $full;
  z-index: 1;
  @include angle(after);
  @include angle(before, true);

  @include tablet {
    padding: $small $half;
  }

  @include mobile {
    padding: 30px;
    margin-bottom: $small;
  }
}

.projects_title {
  color: $color-main;
  text-shadow: text3D(3, #FFF);
}

.projects_gallery {
  @extend %centered;
  @include flexbox(row, space-between);
  flex-wrap: wrap;
  gap: $half;
  list-style: none;
  margin: 0;
  padding: 0;

  @include tablet {
    margin-top: $small;
  }

  @include mobile {
    gap: 25px;
  }
}