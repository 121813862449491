.footer_container {
  @include flexbox(row, center, center);
  gap: $half;
  padding: $small;
  border-top: 1px solid $color-light;
  box-shadow: 0 0 1px 2px #2dffbc40;

  @include mobile {
    gap: $small;
  }
}

.vIcon {
  height: 40px;
  object-fit: contain;

  @include mobile {
    height: 24px;
  }
}

.vIcon img {
  height: 100%;
}

.vIcon.right {
  transform: rotate(180deg)
}

.footer_copyright {
  color: $color-light;
  line-height: 27px;
  margin: 0;
}