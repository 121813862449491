.hero {
  @extend %centered;
  @include flexbox(row, space-between, flex-start);
  height: 30%;
  padding: $half $full;
  margin-bottom: $half;

  @include tablet {
    padding: 10px $half;
  }

  @include mobile {
    padding: 30px 10px $half 30px;
    margin-bottom: $small;
  }
}

.hero_main {
  max-width: 65%;
  @include flexbox(row, flex-start);
  object-fit: contain;

  @include mobile {
    max-width: 100%;
    width: 100%;
  }
}

.hero_info {
  @include flexbox(column, flex-start);
  max-width: 550px;
  z-index: 50;
  margin-right: $half;

  @include desktopXL {
    max-width: 700px;
  }

  @include mobile {
    margin-right: -$half;
  }

  p {
    font-size: 1.2rem;

    @include mobile {
      margin-top: 10px;
    }
  }

  strong {
    font-weight: 500;
  }
}

.hero_stack {
  @include flexbox(row, flex-start);
  gap: $small;
  flex-wrap: nowrap;
  margin: 0 0 $small;

  @include mobile {
    @include flexbox(row, space-between);
    flex-wrap: wrap;
    margin: 0 0 10px;
  }
}

.stack_item {
  @include flexbox(column, space-between, center);
  font: map-get($textstyle, small);
  margin: 0;
  min-width: max-content;

  @include mobile {
    flex: 1 0 25%;
  }
}

.stack_item>img {
  height: 40px;
  margin-bottom: 10px;
  filter: saturate(0) brightness(200%);

  @include mobile {
    height: 35px;
    margin-bottom: 8px;
  }
}

.hero_buttons {
  @include flexbox(row, flex-start);
  gap: $half;

  @include mobile {
    @include flexbox(row, space-between);
    gap: 15px;
  }
}

.hero_image {
  max-height: 500px;
  min-width: 200px;
  margin-top: $half;
  margin-left: -200px;

  mix-blend-mode: multiply;
  filter: opacity(90%);

  @include tablet {
    margin-right: 0px;
  }

  @include mobile {
    margin-top: -10px;
  }
}

.hero_image>img {
  width: 100%;
  z-index: -1;
}

// Element below not shown in tablet or mobile sizes:

.hero_aside {
  @include flexbox(row, center, center);

  @include desktopXL {
    margin-right: 100px;
  }
}

.hero_aside-background {
  flex: 1 1 100%;
  filter: opacity(5%);
  margin-right: -280px;
}

.hero_aside-background>img {
  width: 100%;
  max-height: 400px;
  z-index: -1;
  object-fit: contain;
}

.hero_function {
  @include flexbox(column, flex-start);
  font: map-get($textstyle, code);
  color: $color-accent;
  font-size: 1rem;
  line-height: 1rem;
  min-width: max-content;
  z-index: 50;
}

.typed {
  display: inline-flex;
}

.typed span {
  word-break: break-all;
  height: 1rem;
  width: 0%;
  overflow: hidden;
  animation: typewriter 3.3s linear forwards alternate;
  animation-delay: 1s;
}

.typed span:before {
  content: " ";
  display: inline-block;
}

.typed.line_2 span {
  margin-left: $small;
  animation-delay: 3.5s;
  animation-duration: 3.2s;
}

.typed.line_3 span {
  animation-delay: 6.8s;
  animation-duration: 0.2s;
}

@keyframes typewriter {

  90%,
  100% {
    width: 100%
  }
}