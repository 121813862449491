.contact_container {
  @include flexbox(column, flex-start);
  padding: $half $full;
  position: relative;

  @include tablet {
    padding: $small $half $half;
    margin-bottom: $small;
  }

  @include mobile {
    padding: 30px $small;
    margin-bottom: 0;
  }

  @include desktopXL {
    @include flexbox(column, flex-start, center);
  }
}

.contact_title {
  color: #FFF;
  text-shadow: text3D(3, $color-accent);
}

.contact_subtext {
  @include flexbox(row, flex-start);
  gap: $small;
  margin-bottom: $small;

  & p {
    font-family: $font-code;
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0 0 $small;
  }

  @include mobile {
    @include flexbox(column, flex-start);
    gap: 0;

    & p {
      margin: 0 0 2px;
      font-size: 1rem;
    }
  }
}

.contact_form {
  flex: 0 1 80%;
  max-width: 900px;
  height: min-content;
  @include flexbox(column, flex-start);
  background-color: $color-light;
  padding: 30px $half $half;
  border-radius: $radius-lg;
  border: 4px solid $color-alt;
  box-shadow: 6px 6px 0 0 $color-alt;

  @include mobile {
    background-color: $color-main;
    padding: 0;
    border-radius: none;
    border: none;
    box-shadow: none;
  }

  @include desktopXL {
    max-width: 1200px;
    width: 1200px;
  }
}

.sender {
  @include flexbox(row, space-between);
  gap: $half;
  margin-bottom: $small;

  @include tablet {
    @include flexbox(column, flex-start);
    gap: $small;
  }

  @include mobile {
    @include flexbox(column, flex-start);
    gap: $small;
  }
}

.form_group {
  flex: 1 1 50%;
  @include flexbox(column, flex-start);
}

.form_group.message {
  flex: 1 1 100%;
  @include flexbox(column, flex-start);
}

.form_label {
  font: map-get($textstyle, bold);
  color: $color-main;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include mobile {
    color: #FFF;
    margin-bottom: 5px;
  }
}

.form_input {
  padding: 8px;
  color: $color-main;
  font-family: $font-code;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: #FFF;
  border-radius: $radius-med;
  border: 2px solid $color-main;

  &::placeholder {
    color: #8FC5A4;
    font-family: $font-code;
    font-weight: 300;
  }

  @include mobile {
    border-radius: 10px;
    background-color: #ECF6ED;
    border: 2px solid $color-alt;
  }
}

.form_message {
  height: 140px;
  padding: 8px;
  background-color: #FFF;
  margin: 0;

  color: $color-main;
  font-family: $font-code;
  font-size: 1.1rem;
  font-weight: 500;

  border-radius: $radius-med;
  border: 4px inset $color-alt;

  &::placeholder {
    color: #8FC5A4;
    font-family: $font-code;
    font-weight: 300;
  }

  @include mobile {
    background-color: #ECF6ED;
    border-radius: 10px;
    border: 2px solid $color-alt;
  }
}


.button-submit {
  align-self: center;
  color: $color-main;
  font: map-get($textstyle, code);
  font-weight: 600;
  font-size: 1.2rem;
  width: 300px;
  margin-top: 20px;
  text-decoration: none;
  text-align: center;
  padding: 10px 12px 8px;
  background-color: #FFF;
  border-radius: $radius-med;
  border: 4px solid $color-accent;
  box-shadow: 4px 4px 0 0 $color-accent;
  box-shadow: 5px 5px 3px 0px #4c1c84c7;

  &:hover {
    color: #FFF;
    background-color: #4c1c84;
    border: 4px solid #4c1c84;
    box-shadow: 4px 4px 0 0 #4c1c84;
    box-shadow: 5px 5px 3px 0px #2b0b4fc7;
    transition: 0.2s ease all;
  }

  @include mobile {
    width: 160px;
    padding: 8px 10px 6px;
    border: 3px solid $color-accent;
    box-shadow: 3px 3px 0 0 $color-accent;
    box-shadow: 4px 4px 2px 0px #4c1c84c7;
  }
}

.form_error {
  color: #FFF;
  text-align: center;
  margin-bottom: -$half;

  & p {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0;
  }

  @include mobile {
    font-size: 1rem;
    margin: 20px 0 0px;
  }
}

.form_error a {
  font-weight: 500;
  font-size: 1.3rem;
  color: $color-accent;

  &:hover {
    color: $color-light;
  }
}

.contact_form.success {
  flex: 0 1 80%;

  &>p {
    color: #4c1c84;
    font: map-get($textstyle, xlarge);
    font-weight: 500;
    text-align: center;
  }

  @include tablet {
    flex: 0 1 60%;
  }
}